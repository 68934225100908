import ApiService from '@/services/api.service';

// action types
export const GET_FILES = 'getFiles';
export const UPLOAD_FILE = 'uploadFile'
export const DELETE_FILE = 'deleteFile'

// getters

export const GET_MF_FILES = 'getMfFiles'
export const GET_LOADING_FILES = 'getLoadingFiles'
export const SET_MF_FILES = 'setMfFiles'

const state = {
  mfFiles: [],
  loadingFiles:false,
};

const getters = {
  [GET_MF_FILES](state) {
    return state.mfFiles;
  },
};

const actions = {
  async [GET_FILES](context, payload) {
    const currentUserId = context.rootState.auth.user.id;
    context.state.loadingFiles = true;
    const fields = `&fields[]=id&fields[]=modified_on&fields[]=type&fields[]=title&fields[]=type&fields[]=filesize`;
    const sort = `&sort[]=-uploaded_on&page=1`;
    let filter = `&filter[_and][0][type][_nnull]=true`;
    if(!payload?.mf)
    {
      filter += `&filter[_and][1][uploaded_by][_eq]=${currentUserId}`
    }
    let url = `files?limit=-1${fields}${sort}${filter}`
    if(payload?.mf)
    {
      url+= `&filter[_and][2][mf][_eq]=${payload.mf}`
    }
    const response = await ApiService.get(
      url
    );
    context.state.loadingFiles = false;
    if(payload.mf)
    {
      context.commit(SET_MF_FILES, response.data.data);
    }
    return response.data.data;
  },
  async [UPLOAD_FILE](context, payload) {
    console.log('payload',payload)
    const result = await ApiService.upload(payload.data);
    if(payload.isMfFile)
    {
      context.commit(SET_MF_FILES, context.state.mfFiles.concat(result));
    }
    return result;
  },
  async [DELETE_FILE](context,payload) {
    const result = await ApiService.delete(payload);
    return result;
  },
};

const mutations = {
  [SET_MF_FILES](state, payload) {
    state.mfFiles = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
