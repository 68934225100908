import ApiService from '@/services/api.service';
import IdbService from '@/services/idb.service';

// action types
export const FIND_MEDICAL_FILE_BY_NAME = 'findMedicalFileByName';
export const FIND_MEDICAL_FILE_BY_ID = 'findMedicalFileById';
export const FETCH_CACHED_MEDICAL_FILES = 'fetchCachedMedicalFiles';
export const CREATE_MEDICAL_FILE = 'createMedicalFile';
export const UPDATE_MEDICAL_FILE = 'updateMedicalFile';
export const CACHE_MEDICAL_FILE = 'cacheMedicalFile';
export const FETCH_ENTRIES = 'fetchMedicalFileEntries';
export const FETCH_ENTRY = 'fetchMedicalFileEntry';
export const CREATE_ENTRY = 'createMedicalFileEntry';
export const UPDATE_ENTRY = 'updateMedicalFileEntry';
export const DELETE_ENTRY = 'deleteMedicalFileEntry';

// getters

export const GET_MEDICAL_FILE = 'getMedicalFile';
export const GET_CACHED_MEDICAL_FILES = 'getCachedMedicalFiles';

export const GET_HISTORY = 'getMedicalFileHistory';
export const GET_HISTORY_PROGRESS = 'getMedicalFileHistoryProgress';

export const GET_VITALS = 'getMedicalFileVitals';
export const GET_VITAL_PROGRESS = 'getMedicalFileVitalsProgress';

export const GET_DIAGNOSES = 'getMedicalFileDiagnoses';
export const GET_DIAGNOSES_PROGRESS = 'getMedicalFileDiagnosesProgress';

export const GET_NOTES = 'getMedicalFileNotes';
export const GET_NOTES_PROGRESS = 'getMedicalFileNotesProgress';

export const GET_PRESCRIPTIONS = 'getMedicalFilePrescriptions';
export const GET_PRESCRIPTIONS_PROGRESS = 'getMedicalFilePrescriptionsProgress';

// mutation types
export const SET_MEDICAL_FILE = 'setFile';
export const SET_CACHED_MEDICAL_FILES = 'setCachedMedicalFiles';
export const SET_HISTORY = 'setMedicalFileHistory';
export const SET_VITALS = 'setMedicalFileVitals';
export const SET_DIAGNOSES = 'setMedicalFileDiagnoses';
export const SET_NOTES = 'setMedicalFileNotes';
export const SET_PRESCRIPTIONS = 'setMedicalFilePrescriptions';

const medicalFileEntries = [
  {
    entryName: 'md_history',
    fields:
      'id,history_type,history_body,incident_date,created_on,modified_on',
  },
  {
    entryName: 'md_vitals',
    fields: 'id,vital_type,reading,health,method,created_on,modified_on',
  },
  {
    entryName: 'md_diagnoses',
    fields: 'id,examination,certainty,icd,created_on,modified_on',
  },
  { entryName: 'md_notes', fields: 'id,note_body,created_on,modified_on' },
  {
    entryName: 'md_prescription',
    fields:
      'id,pdf_file,owner.first_name,owner.last_name,notes,medication.dosage,medication.frequancy,medication.number_of_days,medication.times,medication.medicine,created_on,modified_on',
  },
];

const state = {
  file: null,
  cachedFiles: [],
  history: [],
  vitals: [],
  diagnoses: [],
  notes: [],
  prescription: [],
  historyLoading: false,
  vitalsLoading: false,
  diagnosesLoading: false,
  notesLoading: false,
  prescriptionLoading: false,
};

const getters = {
  [GET_MEDICAL_FILE](state) {
    return state.file;
  },
  [GET_CACHED_MEDICAL_FILES](state) {
    return state.cachedFiles;
  },
  [GET_HISTORY](state) {
    return state.history;
  },
  [GET_HISTORY_PROGRESS](state) {
    return state.historyLoading;
  },
  [GET_VITALS](state) {
    return state.vitals;
  },
  [GET_VITAL_PROGRESS](state) {
    return state.vitalsLoading;
  },
  [GET_DIAGNOSES](state) {
    return state.diagnoses;
  },
  [GET_DIAGNOSES_PROGRESS](state) {
    return state.diagnosesLoading;
  },
  [GET_NOTES](state) {
    return state.notes;
  },
  [GET_NOTES_PROGRESS](state) {
    return state.notesLoading;
  },
  [GET_PRESCRIPTIONS_PROGRESS](state) {
    return state.prescriptionLoading;
  },
  [GET_PRESCRIPTIONS](state) {
    return state.prescription;
  },
};

const actions = {
  async [FIND_MEDICAL_FILE_BY_NAME](context, payload) {
    const response = await ApiService.get(
      'items',
      `medical_file?fields=*&filter[full_name][_contains]=${payload.full_name}`
    );
    return response.data.data;
  },
  async [FIND_MEDICAL_FILE_BY_ID](context, payload) {
    const response = await ApiService.get(
      'items',
      `medical_file?fields=*&filter[id]=${payload.data.id}`
    );
    const file = response.data.data[0];
    if (
      payload
        .dayjs(payload.data.modified_on)
        .isAfter(payload.dayjs(file.modified_on)) ||
      (file.modified_on && !payload.data.modified_on)
    ) {
      delete file.appointments;
      delete file.diagnoses;
      delete file.files;
      delete file.history;
      delete file.notes;
      delete file.prescription;
      delete file.vitals;
      await IdbService.updateStorageItem('files', file);
    }
    return response.data.data;
  },
  async [CACHE_MEDICAL_FILE](context, payload) {
    await IdbService.saveToStorage('files', payload);
    const files = await IdbService.checkStorage('files');
    context.commit(SET_CACHED_MEDICAL_FILES, files);
  },
  async [FETCH_CACHED_MEDICAL_FILES](context) {
    const files = await IdbService.checkStorage('files');
    context.commit(SET_CACHED_MEDICAL_FILES, files);
  },
  async [CREATE_MEDICAL_FILE](context, payload) {
    const response = await ApiService.post('items/medical_file', payload);
    if (response.status === 200) {
      context.dispatch(CACHE_MEDICAL_FILE, response.data.data);
      context.commit(SET_MEDICAL_FILE, response.data.data);
      return true;
    }
    return false;
  },
  async [UPDATE_MEDICAL_FILE](context, payload) {
    const cloned = structuredClone(payload.data);
    delete cloned.appointments;
    delete cloned.diagnoses;
    delete cloned.files;
    delete cloned.history;
    delete cloned.notes;
    delete cloned.prescription;
    delete cloned.vitals;

    console.log(context, cloned);
    const response = await ApiService.update(
      'items/medical_file',
      payload.id,
      cloned
    );
    if (response.status === 200) {
      await IdbService.updateStorageItem('files', payload.data);
      await context.dispatch(FETCH_CACHED_MEDICAL_FILES);
      context.commit(SET_MEDICAL_FILE, cloned);
      return true;
    } else {
      return false;
    }
  },
  async [FETCH_ENTRIES](context) {
    if (!context.state.file) {
      return;
    }
    context.state.historyLoading = true;
    context.state.vitalsLoading = true;
    context.state.diagnosesLoading = true;
    context.state.notesLoading = true;
    context.state.prescriptionsLoading = true;
    for (const entry of medicalFileEntries) {
      context
        .dispatch(FETCH_ENTRY, {
          entryName: entry.entryName,
          fields: entry.fields,
        })
        .then(() => {
          const stateName = entry.entryName.split('_')[1];
          context.state[`${stateName}Loading`] = false;
        });
    }
  },
  async [FETCH_ENTRY](context, payload) {
    const response = await ApiService.get(
      'items',
      `${payload.entryName}?fields=${payload.fields}&filter[medical_file]=${context.state.file.id}`
    );
    const stateName = payload.entryName.split('_')[1];

    context.state[stateName] = response.data.data;
    return response.data.data;
  },
  async [CREATE_ENTRY](context, payload) {
    if (!payload.data.medical_file) {
      payload.data.medical_file = context.state.file.id;
    }
    const response = await ApiService.post(
      `items/${payload.entryName}`,
      payload.data
    );
    if (response.status === 200) {
      const stateName = payload.entryName.split('_')[1];

      if (payload.forceUpdate) {
        const fields = medicalFileEntries.find(
          (e) => e.entryName === payload.entryName
        ).fields;
        await context.dispatch(FETCH_ENTRY, {
          entryName: payload.entryName,
          fields: fields,
        });
      } else {
        context.state[stateName].unshift(response.data.data);
      }
      return true;
    }
    return false;
  },
  async [UPDATE_ENTRY](context, payload) {
    const response = await ApiService.update(
      `items/${payload.entryName}`,
      payload.data.id,
      payload.data
    );
    if (response.status === 200) {
      const stateName = payload.entryName.split('_')[1];
      const entryIndex = context.state[stateName].findIndex(
        (e) => e.id === payload.data.id
      );
      const temp = structuredClone(context.state[stateName]);
      temp[entryIndex] = payload.data;
      context.state[stateName] = temp;
      return true;
    } else return false;
  },
  async [DELETE_ENTRY](context, payload) {
    const stateName = payload.entryName.split('_')[1];
    const entryIndex = context.state[stateName].findIndex(
      (e) => e.id === payload.data.id
    );
    context.state[stateName].splice(entryIndex, 1);
    await ApiService.delete(`items/${payload.entryName}`, payload.data.id);
  },
};

const mutations = {
  [SET_MEDICAL_FILE](state, payload) {
    state.file = payload;
  },
  [SET_CACHED_MEDICAL_FILES](state, payload) {
    state.cachedFiles = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
