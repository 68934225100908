import AgoraRTC from "agora-rtc-sdk-ng";
import axios from 'axios';

// action types
export const INITIATE_ENGINE = 'initiateEngine';
export const JOIN_CALL = 'joinCall';
export const LEAVE_CALL = 'leaveCall';
export const CLOSE_ENGINE = 'closeEngine';
export const GENERATE_TOKEN = 'generateToken';

// getters
export const GET_LOCAL_VIDEO = 'getLocalVideo';
export const GET_LOCAL_AUDIO = 'getLocalAudio';
export const GET_REMOTE = 'getRemote';
export const GET_ENGINE = 'getEngine';
export const GET_REMOTE_USERS_COUNT = 'getRemoteUsersCount';
export const GET_REMOTE_TRACKS_COUNT = 'getRemoteTracksCount';
export const IN_CALL = 'inCall';


const state = {
  // A variable to hold a local audio track.
  localAudioTrack: null,
  // A variable to hold a local video track.
  localVideoTrack: null,
  // A variable to hold a remote audio track.
  remote: [],
  inCall: false,

  engine: null,
};

const getters = {
  [GET_ENGINE](state) {
    return state.engine;
  },
  [GET_LOCAL_VIDEO](state) {
    return state.localVideoTrack;
  },
  [GET_LOCAL_AUDIO](state) {
    return state.localAudioTrack;
  },
  [GET_REMOTE](state) {
    return state.remote;
  },
  [GET_REMOTE_USERS_COUNT](state) {
    return state.remote.length;
  },

  [GET_REMOTE_TRACKS_COUNT](state) {
    let keyValueCount = 0;
    state.remote.forEach(item => {
      keyValueCount += Object.keys(item).length;
    });
    return keyValueCount;
  },
  [IN_CALL](state) {
    return state.inCall;
  }
};

const actions = {
  async [INITIATE_ENGINE](context) {
    context.state.engine = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    context.state.engine.on("user-published", async (user, mediaType) => {
      await context.state.engine.subscribe(user, mediaType);
      const existingRemote = context.state.remote.find((e) => e.uid === user.uid.toString());
      // Subscribe to the remote user when the SDK triggers the "user-published" event.
      
      console.log('existingRemote',existingRemote)
      console.log("subscribe success", user.uid, context.state.remote);
      let newRemote = existingRemote ? existingRemote :{
        uid: user.uid.toString()
      }
      if (mediaType == "video") {
        newRemote.videoTrack = user.videoTrack;
      }
      if (mediaType == "audio") {
        newRemote.audioTrack = user.audioTrack;
      }
      if (!existingRemote)
      {
        context.state.remote.push(newRemote);
      }
      
  });
  
  // Listen for the "user-unpublished" event.
  context.state.engine.on("user-unpublished", (user) => {
      console.log(user.uid + "has left the channel");
      context.state.remote.splice(context.state.remote.findIndex((e) => e.uid === user.uid.toString()), 1);
  });
  },
  async [JOIN_CALL](context, payload) {
    await context.state.engine.join(
      payload.appId,
      payload.channelName,
      payload.token,
      payload.uid
    );

    context.state.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    context.state.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
    context.state.localVideoTrack.on("video-element-visible-status", (data) => {
      console.log("video state changed", data);
    });
    await context.state.engine.publish([
      context.state.localAudioTrack,
      context.state.localVideoTrack,
    ]);
    context.state.inCall = true;
  },
  async [LEAVE_CALL](context) {
    // Destroy the local audio and video tracks.
    context.state.localAudioTrack?.close();
    context.state.localVideoTrack?.close();
    // Remove the containers you created for the local video and remote video.
    await context.state.engine?.leave();
    context.state.remote = [];
    context.state.inCall = false;
    await context.dispatch(CLOSE_ENGINE);
  },
  async [CLOSE_ENGINE](context) {

    context.state.engine = null;
  },
  async [GENERATE_TOKEN](context,payload) {
    console.log('generateToken',payload)
    const response = await axios.get(`https://at.s2.rocksoft.io/rtcToken?channelName=${payload.channelName}&uid=${payload.uid}`);
    console.log('response',response)
    return response.data;
  }
};

export default {
  state,
  actions,
  getters,
};
